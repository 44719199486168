<template>
    <v-dialog
        v-model="app"
        @click:outside="abort"
        width="600"
    >
      <v-card>
        <v-list>

          <v-list-item two-line>

            <v-list-item-avatar
              v-if="app.icon !== null && app.icon.url !== null"
              size="60"
            >
              <v-img :src="app.icon.url"></v-img>
            </v-list-item-avatar>

            <v-list-item-avatar v-else size="60" class="grey">

            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ app.name }} 
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ app.description }}
              </v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>

        </v-list>

        <v-card-text v-if="app.defines === null || app.defines.configFields === null || app.defines.configFields === undefined || app.defines.configFields.length === 0">
          {{this.$t("appDoesNotRequireConfiguration")}}
        </v-card-text>

        <v-card-text v-if="app.defines !== null && app.defines.configFields !== null && app.defines.configFields.length > 0">
          <v-container>
            <v-form ref="onBoardingAppForm">
              <template v-for="configField in app.defines.configFields">
                <v-text-field
                        filled
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'textfield'"
                        :required="configField.required ? 'required' : ''"
                        v-model="appInstanceSettings[configField.key]"
                        :placeholder="configField.placeholder"
                        :hint="configField.description"
                />
                <v-text-field
                        type="password"
                        filled
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'password'"
                        :required="configField.required ? 'required' : ''"
                        v-model="appInstanceSettings[configField.key]"
                        :placeholder="configField.placeholder"
                        :hint="configField.description"
                />
                <v-select
                        filled
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'select'"
                        :required="configField.required ? 'required' : ''"
                        v-model="appInstanceSettings[configField.key]"
                        :placeholder="configField.placeholder"
                        :hint="configField.description"
                        :items="configField.options"
                        item-value="key"
                        item-text="label"
                />
                <tiptap-vuetify
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'textarea'"
                        :required="configField.required ? 'required' : ''"
                        v-model="appInstanceSettings[configField.key]"
                        :extensions="extensions"
                        :placeholder="configField.placeholder"
                        :card-props="{ flat: true, color: '#f5f5f5', border: '1px solid #ccc' }"
                />
                <v-switch
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'checkbox'"
                        v-model="appInstanceSettings[configField.key]"
                        :hint="configField.description"
                />
              </template>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text color="red" @click="abort">{{$t('abort')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            @click="updateAppInstace"
            :loading="processingUpdate"
          >
          {{$t('update')}}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>
<script>


export default {
    name: 'AppInstanceEditDialog',
    props: {
        app: {
          type: Object,
          default: () => {},
          required: true
        },
        abort: {
            type: Function,
            default: () => {},
            required: true
        },
        appInstanceUUID: {
          type: String,
          deafult: () => '',
          required: true
        },
        appInstanceSettings: {
            type: Object,
            default: () => {},
            required: false
        }
	  },
    data: function() {
      return {
        processingUpdate: false
      }
    },
    computed: {

    },
    methods: {
      updateAppInstace() {
        this.processingUpdate = true

        const payload = {
          appInstanceUUID: this.appInstanceUUID,
          appInstanceSettings: this.appInstanceSettings
        }

        console.log("Payload going to store")
        console.log(payload)

        this.$store.dispatch('updateAppInstanceSettings', payload).then(result => {
          if (result && result === true) {
            this.$store.commit('updateActionSuccess', {
              message: 'Success',
              subMessage: 'Successfully updated the app instance version.'
            })
          }else {
            this.$store.commit('updateActionError', {
              message: 'Failure',
              subMessage: 'Unknown error occured while trying to update app instance settings.'
            })
          }
        }).catch((e) => {
          this.$store.commit('updateActionError', {
            message: 'Failure',
            subMessage: 'Unknown error occured while trying to update app instance settings.'
          })
        }).finally(() => {
          this.processingUpdate = false
          this.abort()
        })
      }
    },
    mounted() {

	},
}
</script>